.create-form {
    background-color: #1e1e26;
    padding: 20px;
    color: #ffffff;
    border-radius: 8px;
    margin-top: 20px;
    border: 1px solid #444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
    
    .create-form h2 {
      margin-bottom: 20px;
    }
    
    .create-form label {
      display: block;
      margin-bottom: 10px;
    }
    
    .create-form input {
      width: 100%;
      padding: 8px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }
    
    .create-form edit-button {
      cursor: pointer;
      padding: 10px;
      background-color: #0c59ac;
      color: #fff;
      border: none;
      border-radius: 4px;
      margin-right: 10px;
      transition: background-color 0.3s ease;
    }
    
    .create-form edit-button:hover {
      background-color: #0056b3;
    }
  
    .create-form .cancel-button {
      background-color: rgb(194, 27, 27);
    }
  
    .create-form .cancel-button:hover {
      background-color: rgb(108, 17, 17);
    }
    