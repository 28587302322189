.header {
  background-color: rgba(30, 30, 38, 0.6);
  padding: 20px;
  text-align: center;
  font-family: 'Oswald', sans-serif;
}

.title {
  margin: 0;
  color: #fff;
}
