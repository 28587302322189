.details-module {
  background-color: #1e1e26;
  padding: 20px;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.details-module th {
  background-color: #2c2c35;
  padding: 12px;
  text-align: left;
}

.details-module td {
  padding: 12px;
  border-bottom: 1px solid #393939;
}

.details-module tr:hover {
  background-color: #2c2c35;
}

.details-module a {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.details-module button {
  background-color: #3498db;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-module button:hover {
  background-color: #2077b3;
}

.has-image {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}
