.body-unauthorized {
    font-family: Arial, sans-serif;
    background-color: black;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container-unauthorized {
    text-align: center;
  }
  
  .h1-unauthorized {
    margin-bottom: 20px;
    color: white;
  }
  
  .p-unauthorized {
    color: white;
    margin-bottom: 20px;
  }
  
  .link-unauthorized {
    color: #007bff;
    text-decoration: none;
  }
  
  .link-unauthorized:hover {
    color: #0056b3;
  }
  