.collection-table {
  background-color: #1e1e26;
  padding: 20px;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

@media (max-width: 767px) {
  .collection-table {
    border: 0;
    padding: 0;
    background-color: transparent;
  }
}

.collection-table h2 {
  margin-bottom: 20px;
  color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 8px;
  border: 1px solid #444444;
  text-align: left;
  color: #fff;
}

th {
  background-color: black;
}

tr {
  transition: background-color 0.3s ease;
}

tr:hover {
  background-color: #504d6f;
}

.selected-item {
  background-color: rgb(23, 20, 54) !important;
}

button {
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  background-color: #3b3295;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin: 5px 5px;
}

button:disabled {
  cursor: not-allowed;
}

button:hover {
  background-color: rgb(30, 30, 99);
}

.create-button {
  background-color: transparent;
}

.create-button img {
  width: 25px;
  height: 25px;
  filter: invert(1) opacity(0.9);
}

.create-button:hover {
  background-color: green;
}

.edit-button {
  background-color: transparent;
}

.edit-button img {
  width: 25px;
  height: 25px;
  filter: invert(1) opacity(0.9);
}

.edit-button:hover {
  background-color: #1d126e;
}

.delete-button {
  background-color: transparent;
  padding: 8px;
}

.delete-button img {
  width: 25px;
  height: 25px;
  filter: invert(1) opacity(0.9);
}

.delete-button:hover {
  background-color: rgb(163, 30, 30);
}

.has-image {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

@media (max-width: 767px) {
  th:first-child,
  td:first-child {
    display: none;
  }
}
